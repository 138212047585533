import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import plugin from '@/plugin'
import components from '@/components'
import shipoutUi from '@shipout/ui/lib'
import '@/styles/index.scss'
import i18n from './i18n'

Vue.use(shipoutUi, {
  size: 'medium',
  i18n: (key, value) => {
    return i18n.t(key, value)
  }
})
// 全局组件注册
Vue.use(components)
// 全局插件注册
Vue.use(plugin)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app-placeholder')
