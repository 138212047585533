import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: {},
    detailCaseNo: null // 详情页面工单号，用于面包屑
  },
  mutations: {
    setUserInfo (state, data) {
      state.userInfo = data
    },
    setDetailCaseNo (state, caseNo) {
      state.detailCaseNo = caseNo
    }
  },
  actions: {
    getUserInfo ({ commit }) {
      const userInfo = localStorage.getItem('userInfo')
      if (userInfo) {
        commit('setUserInfo', JSON.parse(userInfo))
      }
    }
  },
  modules: {
  }
})
