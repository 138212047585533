export default {
  createCase: '创建工单',
  subject: '主题',
  ticketType: '工单类型',
  description: '描述',
  attachment: '附件',
  submit: '提交',
  caseNo: '工单号',
  latestReply: '最新回复',
  updateTime: '更新时间',
  createTime: '创建时间',
  status: '状态',
  action: '操作',
  startTime: '开始时间',
  endTime: '结束时间',
  clickUpload: '点击上传',
  dragTo: '或拖动文件上传',
  contactSupport: 'Contact Support',
  supportCase: 'Support Case',
  createSupportCase: '创建工单',
  all: '全部',
  search: '搜索',
  assign: '分配',
  reply: '回复',
  receive: '领取',
  close: '关闭',
  transfer: '转移',
  reopen: '重新打开',
  cancel: '撤销',
  processing: '处理中',
  closed: '已关闭',
  cancelled: '已取消',
  addToCon: '输入消息内容',
  confirm: '确定',
  cancelBtn: '取消',
  reopenTip: '确定重新提交这个工单吗？',
  cancelTip: '确定撤销这个工单吗？',
  successTip: '操作成功',
  fileNumExceedTip: '最多上传{num}个文件',
  fileMaxSizeTip: '文件最大尺寸{num}M',
  required: '必填',
  copy: '复制',
  rate: {
    solveDesc: '本次问题是否已解决？',
    starDesc: '请为本次服务打分：',
    submitted: '谢谢，您的评价已提交！',
    yes: '是的，已解决',
    no: '没有解决'
  }
}
