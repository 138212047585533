import i18n from '../i18n'
import qs from 'qs'

// 语言设置
const { language } = qs.parse(location.href)
language && (i18n.locale = language)

export const COMPONENT_TYPES = {
  INPUT: {
    VALUE: 1,
    LABEL: 'Text Input'
  },
  SELECT: {
    VALUE: 2,
    LABEL: 'Drop List'
  },
  RADIO: {
    VALUE: 3,
    LABEL: 'Single Selection'
  },
  CHECKBOX: {
    VALUE: 4,
    LABEL: 'Multiple Selection'
  },
  TEXT: {
    VALUE: 5,
    LABEL: 'Text'
  }
}

export const BUSINESSES_GENERAL = {
  VALUE: 'General',
  LABEL: 'General'
}

export const BUSINESSES = {
  EZEESHIP: {
    VALUE: 'EZEESHIP',
    LABEL: 'EzeeShip'
  },
  SHIPOUT: {
    VALUE: 'SHIPOUT',
    LABEL: 'ShipOut'
  },
  WMS: {
    VALUE: 'WMS',
    LABEL: 'WMS'
  },
  SHIPA2Z: {
    VALUE: 'SHIPA2Z',
    LABEL: 'ShipA2Z'
  }
}

export const ACTIONS = {
  ASSIGN: i18n.t('common.assign'),
  REPLY: i18n.t('common.reply'),
  RECEIVE: i18n.t('common.receive'),
  CLOSE: i18n.t('common.close'),
  TRANSFER: i18n.t('common.transfer'),
  REOPEN: i18n.t('common.reopen'),
  WITHDRAWN: i18n.t('common.cancel')
}

export const TICKET_STATUS = { // 客户端状态值不同与admin端工单状态值（客户端processing状态包含了pending, await）
  PROCESSING: {
    VALUE: 2,
    LABEL: i18n.t('common.processing'),
    COLOR: '#337AF4',
    ACTIONS: [ACTIONS.REPLY, ACTIONS.WITHDRAWN]
  },
  CLOSED: {
    VALUE: 3,
    LABEL: i18n.t('common.closed'),
    COLOR: '#33A00F',
    ACTIONS: [ACTIONS.REOPEN]
  },
  WITHDRAWN: {
    VALUE: 4,
    LABEL: i18n.t('common.cancelled'),
    COLOR: '#999999',
    ACTIONS: [ACTIONS.REOPEN]
  }
}

export const PRIORITY = [1, 2, 3, 4, 5]

export const FILE_TYPES = {
  UNKNOWN: {
    ICON: 'oms__icon_weizhiwendang',
    VALUE: 0,
    SUFFIX: []
  },
  PDF: {
    ICON: 'oms__icon_PDF',
    VALUE: 1,
    SUFFIX: ['pdf', 'pptx']
  },
  EXCEL: {
    ICON: 'oms__icon_XLS',
    VALUE: 2,
    SUFFIX: ['xls', 'csv', 'xlsx']
  },
  DOC: {
    ICON: 'oms__icon_DOC',
    VALUE: 3,
    SUFFIX: ['doc']
  },
  IMG: {
    ICON: 'oms__icon_PIC',
    VALUE: 4,
    SUFFIX: ['png', 'jpg', 'jpeg']
  }
}

export const SYSTEM_MSG_TEMPLATE = {
  BORN: {
    en: 'USERA <span class="action-name">created</span> this case at TIME',
    cn: 'USERA在TIME<span class="action-name">创建</span>了工单'
  },
  CLOSE: {
    en: 'Customer Service <span class="action-name">closed</span> this case at TIME',
    cn: '客服在TIME<span class="action-name">完结</span>了工单'
  },
  RE_OPEN: {
    en: 'USERA <span class="action-name">re-opened</span> this case at TIME',
    cn: 'USERA在TIME<span class="action-name">重新打开</span>此工单'
  },
  WITHDRAW: {
    en: 'You <span class="action-name">cancelled</span> this case at TIME',
    cn: '您在TIME<span class="action-name">撤销</span>了此工单'
  }
}

// 时间格式展示时的默认格式
export const DATE_FORMAT_SHOW_BASE = 'MM/DD/YYYY'
export const DATE_TIME_FORMAT_SHOW_BASE = 'MM/DD/YYYY HH:mm:ss'

// 时间格式传参时的默认格式
export const DATE_FORMAT_PARAM_BASE = 'YYYY-MM-DD'
export const DATE_TIME_FORMAT_PARAM_BASE = 'YYYY-MM-DD HH:mm:ss'

// 默认无数据时的占位符
export const DEFAULT_NO_DATA_SYMBOL = '-'
