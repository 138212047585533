<template>
  <ez-breadcrumb
    class="ticket-breadcrumb"
    separator-class="ez-icon-arrow-right"
  >
    <template v-for="item in items">
      <ez-breadcrumb-item
        v-if="item.path"
        :key="item.label"
        :to="{ path: item.path }"
      >
        {{ $t(item.label) }}
      </ez-breadcrumb-item>
      <ez-breadcrumb-item
        v-else
        :key="item.label"
      >
        {{ $t(item.label) }}
      </ez-breadcrumb-item>
    </template>
  </ez-breadcrumb>
</template>

<script>
export default {
  name: 'BreadCrumbs',
  props: {
    items: {
      type: Array,
      default () {
        return []
      }
    }
  }
}
</script>

<style scoped lang="scss">
.ticket-breadcrumb{
}
</style>
