import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

Vue.use(Router)

const routes = [
  {
    path: '/test',
    name: 'test',
    component: () => import('@/views/test')
  },
  {
    path: '/redirect',
    name: 'redirect',
    component: () => import('@/views/Redirect')
  },
  {
    path: '/:source',
    component: () => import('@/layout/Layout'),
    children: [
      {
        path: 'list',
        name: 'ticket-list',
        component: () => import('@/views/list/List.vue')
      },
      {
        path: 'create',
        name: 'ticket-create',
        component: () => import('@/views/create/Create')
      },
      {
        path: 'detail/:ticketCaseId',
        name: 'ticket-detail',
        component: () => import('@/views/detail/TicketDetail')
      }
    ]
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404')
  }
]

const router = new Router({
  routes
})

router.beforeEach((to, from, next) => {
  const at = localStorage.getItem('access_token')
  if (to.name === 'redirect') {
    return next()
  }
  if (at) {
    if (to.name !== '404') {
      store.dispatch('getUserInfo')
      next()
    } else {
      next({ name: 'list' })
    }
  } else {
    if (to.name !== '404') {
      next({ name: '404' })
    } else {
      next()
    }
  }
})

export default router
