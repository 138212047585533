// 全局过滤器
import {
  toLocalTime,
  toUtcTime
} from '@/utils/utils'
import { DEFAULT_NO_DATA_SYMBOL } from '@/config'

export default {
  toLocalTime (value, format) {
    return value ? toLocalTime(value, format) : DEFAULT_NO_DATA_SYMBOL
  },
  toUtcTime (value, format) {
    return value ? toUtcTime(value, format) : DEFAULT_NO_DATA_SYMBOL
  }
}
