import Vue from 'vue'
import VueI18n from 'vue-i18n'
import uiEN from '@shipout/ui/lib/locale/lang/en'
import uiCN from '@shipout/ui/lib/locale/lang/zh-CN'

Vue.use(VueI18n)

/**
 * 根据 context 对象拿到对应的值
 * @param {object} contextObj
 * @return {object}
 * */
function getLocalMsg (contextObj) {
  const messages = {}
  contextObj.keys().forEach(fileNameFull => {
    const matched = fileNameFull.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const fileName = matched[1]
      messages[fileName] = contextObj(fileNameFull).default
    }
  })
  return messages
}

function loadLocaleMessages () {
  const moduleLocalsEN = require.context('./languages/en', true, /[A-Za-z0-9-_,\s]+\.js$/i)
  const moduleLocalsCN = require.context('./languages/cn', true, /[A-Za-z0-9-_,\s]+\.js$/i)
  /**
   * 注意！此处的 Key 必须跟对应文件夹的名字保持一致！
   * */
  return {
    en: {
      ...getLocalMsg(moduleLocalsEN),
      ...uiEN
    },
    cn: {
      ...getLocalMsg(moduleLocalsCN),
      ...uiCN
    }
  }
}

let locale
try {
  let { language } = JSON.parse(localStorage.getItem('userInfo'))
  locale = language
} catch (error) {
  console.error(error)
}

const i18n = new VueI18n({
  locale: locale || 'en',
  fallbackLocale: 'en',
  messages: loadLocaleMessages(),
  silentTranslationWarn: true
})

export default i18n
