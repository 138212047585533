export default {
  createCase: 'Create Case',
  subject: 'Subject',
  ticketType: 'Type',
  description: 'Description',
  attachment: 'Attachment',
  submit: 'Submit',
  caseNo: 'Case #',
  latestReply: 'Latest Reply',
  updateTime: 'Updated Time',
  createTime: 'Created Time',
  status: 'Status',
  action: 'Action',
  startTime: 'Start time',
  endTime: 'End time',
  clickUpload: 'Add File',
  dragTo: 'or drop files to upload',
  contactSupport: 'Contact Support',
  supportCase: 'Support Case',
  createSupportCase: 'Create Support Case',
  all: 'All',
  search: 'Search',
  assign: 'Assign',
  reply: 'Reply',
  receive: 'Receive',
  close: 'Close',
  transfer: 'Transfer',
  reopen: 'Reopen',
  cancel: 'Cancel',
  processing: 'Processing',
  closed: 'Closed',
  cancelled: 'Cancelled',
  addToCon: 'Add to conversation',
  confirm: 'Confirm',
  cancelBtn: 'Cancel',
  reopenTip: 'Do you confirm to reopen this support case?',
  cancelTip: 'Do you confirm to cancel this support case?',
  successTip: 'success',
  fileNumExceedTip: 'A maximum of {num} files can be uploaded',
  fileMaxSizeTip: 'The maximum size of the file is {num}M',
  required: 'required',
  copy: 'Copy',
  rate: {
    solveDesc: 'Was the service solve your problem?',
    starDesc: 'Please leave your score for the service:',
    submitted: 'Thank you, your review has been submitted!',
    yes: 'Yes',
    no: 'No'
  }
}
