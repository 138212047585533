<template>
  <div id="app" class="bg-gradient-to-b">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>
