import TagInput from './TagInput'
import GroupField from './GroupField'
import BreadCrumbs from './BreadCrumbs'
import FileName from './FileName'
import TextCopy from './TextCopy'

export default {
  install (Vue) {
    Vue.component(TagInput.name, TagInput)
    Vue.component(GroupField.name, GroupField)
    Vue.component(BreadCrumbs.name, BreadCrumbs)
    Vue.component(FileName.name, FileName)
    Vue.component(TextCopy.name, TextCopy)
  }
}
