import {
  DATE_TIME_FORMAT_PARAM_BASE, DATE_TIME_FORMAT_SHOW_BASE, FILE_TYPES

} from '@/config'
import moment from 'moment-timezone'

/**
 * 生成唯一值
 * @return {string}
 */
export function generateUUID () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0
    const v = c === 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}

export function isValueValid (val) {
  return !((!val && val !== 0 && val !== false) ||
    (Array.isArray(val) && val.length === 0) ||
    (typeof val === 'object' && Object.keys(val).length === 0))
}

export function removeObjectEmptyValue (obj) {
  const output = {}
  Object.keys(obj || {}).forEach(keyName => {
    if (isValueValid(obj[keyName])) output[keyName] = obj[keyName]
  })
  return output
}

/**
 * 获取用户的时区信息
 */
function getUserTimezone () {
  try {
    const { timeZone } = JSON.parse(localStorage.getItem('userInfo'))
    return timeZone
  } catch (error) {
    console.error(error)
  }
}

/**
 * 用户转UTC时间
 * @param {Date} time 本地时间
 * @param {String} format
 * @returns {String} UTC时间
 */
export function toUtcTime (time, format = DATE_TIME_FORMAT_PARAM_BASE) {
  const timezone = getUserTimezone()
  if (timezone) {
    return moment.tz(time, timezone).utc().format(format)
  }
  return time
}

/**
 * UTC转用户时间
 * @param {String} time UTC时间
 * @param {String} format
 * @returns {String} 本地时间
 */
export function toLocalTime (time, format = DATE_TIME_FORMAT_SHOW_BASE) {
  const utcMoment = moment.tz(time, 'UTC')
  const timezone = getUserTimezone()
  if (timezone) {
    const userMoment = utcMoment.clone().tz(timezone)
    return userMoment.format(format)
  }
  return time
}

/**
 * 获取文件url
 * @param url
 * @param fileName
 * @return {*}
 */
export function getFileUrl (url, fileName) {
  let index = fileName.lastIndexOf('.')
  let suffix = fileName.slice(index + 1)
  if (FILE_TYPES.IMG.SUFFIX.includes(suffix)) { // 图片不需要下载
    return url
  }
  return `${url}?attname=${encodeURIComponent(fileName)}`
}

/**
 * 从缓存获取用户信息
 * @return {{}|any}
 */
export function getUserInfo () {
  const userInfo = localStorage.getItem('userInfo')
  if (userInfo) {
    return JSON.parse(userInfo)
  } else {
    return {}
  }
}

/**
 * 从html中获取文本内容
 */
export function getTextFromHtml (htmlStr) {
  const container = document.createElement('div')
  container.innerHTML = htmlStr
  return container.innerText
}
