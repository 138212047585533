<template>
  <div class="c--tag-input">
    <div class="input-wrap">
      <div class="tags-wrap">
        <div class="tag" v-for="item in tags" :key="item.label">
          {{ item.label }}
        </div>
      </div>
      <input type="text" class="input-inner" :value="inputValue" @blur="handleBlur" @input="handleInput" @keyup="handleKeyUp">
    </div>
    <div class="dropdown-trigger" @click="handleShowDropdown"></div>
    <div class="dropdown" v-show="isShowDropDown">
      <div class="title">{{ suggestionName }}</div>
      <div
        class="dropdown-item"
        :class="{ 'is-select': item.isSelected, 'is-new': item.isNew }"
        @click="handleSelect(item)"
        v-for="item in suggestions"
        :key="item.label">
        {{ item.label }}
      </div>
    </div>
  </div>
</template>

<script>
const APPEND_FIX = '(新标签)'
export default {
  name: 'tag-input',
  props: {
    suggestionName: {
      type: String,
      default: '建议'
    },
    source: {
      type: Array,
      default () {
        return ['标签A', '标签B', '标签C']
      }
    }
  },
  data () {
    return {
      inputValue: '',
      isShowDropDown: false,
      tags: [],
      suggestions: []
    }
  },
  mounted () {
    this.setSuggestions()
  },
  methods: {
    setSuggestions () {
      let result = []
      for (let i = 0, len = this.source.length; i < len; i++) {
        const curItem = this.source[i]
        const isInTags = this.tags.includes(curItem)
        const isLikeInputValue = this.inputValue && curItem.includes(this.inputValue)
        if (!isInTags) {
          if (this.inputValue) {
            if (isLikeInputValue) {
              result = result.concat(curItem)
            }
          } else {
            result = result.concat(curItem)
          }
        }
      }
      result = result.map(item => ({ label: item, isSelected: false, isNew: false }))
      if (this.inputValue) {
        result.push({
          label: this.inputValue + APPEND_FIX,
          isSelected: false,
          isNew: true
        })
      }
      if (result.length) result[0].isSelected = true
      this.suggestions = result
    },
    handleKeyUp (e) {
      const index = this.suggestions.findIndex(item => item.isSelected)
      if (index === -1) return
      if (e.keyCode === 38) {
        if (index - 1 >= 0) {
          this.suggestions = this.suggestions.map((item, i) => {
            return {
              ...item,
              isSelected: i === index - 1
            }
          })
        }
      }
      if (e.keyCode === 40) {
        if (index + 1 <= this.suggestions.length - 1) {
          this.suggestions = this.suggestions.map((item, i) => {
            return {
              ...item,
              isSelected: i === index + 1
            }
          })
        }
      }
      if (e.keyCode === 13) {
        const cur = this.suggestions.find(item => item.isSelected)
        this.tags = this.tags.concat(cur)
        this.inputValue = ''
        this.isShowDropDown = false
      }
    },
    handleShowDropdown () {
      this.isShowDropDown = !this.isShowDropDown
    },
    handleInput (e) {
      this.inputValue = e.target.value
      this.setSuggestions()
      this.isShowDropDown = true
    },
    handleBlur () {

    },
    handleSelect (item) {
      this.tags = this.tags.concat(item)
      this.isShowDropDown = false
      this.inputValue = ''
    }
  }
}
</script>

<style scoped lang="scss">
.c--tag-input{
  width: 600px;
  height: 36px;
  display: flex;
  align-items: center;
  position: relative;
  .input-wrap{
    flex: 1;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border: 1px solid #666;
    border-right: none;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    padding: 0 10px;
    .tags-wrap{
      display: flex;
      align-items: center;
      .tag{
        background: #ccc;
        padding: 2px 6px;
        border-radius: 2px;
        font-size: 12px;
        margin-right: 2px;
      }
    }
    .input-inner{
      box-sizing: border-box;
      height: 100%;
      border: none;
      flex: 1;
      &:focus-visible{
        border: none!important;
        outline: none;
      }
    }
  }
  .dropdown-trigger{
    width: 40px;
    height: 100%;
    background: #ccc;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid #666;
    box-sizing: border-box;
    cursor: pointer;
  }
  .dropdown{
    position: absolute;
    left: 0;
    right: 0;
    top: calc(100% + 2px);
    box-shadow: 0 0 10px #ccc;
    border: 1px solid #ccc;
    border-radius: 4px;
    .title{
      font-weight: bold;
      padding: 4px 10px;
    }
    .dropdown-item{
      padding: 4px 10px;
      &:hover{
        background: #eee;
        cursor: pointer;
      }
      &.is-select{
        background: #eee;
      }
      &.is-new{
        border-top: 1px solid #ccc;
      }
    }
  }
}
</style>
