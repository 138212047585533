<template>
  <span class="c--file-name" :class="{ 'is-link': fileUrl }">

    <!--    icon-->
    <ez-icon
      class="file-icon"
      type="svg"
      :icon="fileType.ICON"
    />

    <!--    文件名-->
    <span class="file-name" @click="handleOpen" :title="fileName">
      {{ innerFileName }}
    </span>

    <!--    文件后缀名-->
    <span class="file-name__suffix" @click="handleOpen">{{ suffixText }}</span>

    <!--    关闭按钮-->
    <i
      v-if="close"
      class="ez-icon-close file-name__close-btn"
      @click="handleClose"
    />

  </span>
</template>

<script>
import { FILE_TYPES } from '@/config'

export default {
  name: 'FileName',
  props: {
    fileName: {
      type: String,
      default: ''
    },
    fileUrl: {
      type: String,
      default: ''
    },
    close: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    innerFileName () {
      const index = this.fileName.lastIndexOf('.')
      return this.fileName.slice(0, index - 2).toLowerCase()
    },
    suffixText () {
      const index = this.fileName.lastIndexOf('.')
      return this.fileName.slice(index - 2)
    },
    fileType () {
      const index = this.fileName.lastIndexOf('.')
      const curSuffix = this.fileName.slice(index + 1).toLowerCase()
      const key = Object.keys(FILE_TYPES).find(key => {
        let _suffix = FILE_TYPES[key].SUFFIX
        _suffix = _suffix.map(item => item.toLowerCase())
        return _suffix.includes(curSuffix)
      })
      return key ? FILE_TYPES[key] : FILE_TYPES.UNKNOWN
    }
  },
  methods: {
    handleClose () {
      this.$emit('close', this.fileName, this.fileUrl)
    },
    handleOpen () {
      window.open(this.fileUrl, '_blank')
    }
  }
}
</script>

<style scoped lang="scss">
.c--file-name{
  position: relative;
  display: inline-flex;
  white-space: nowrap;
  align-items: center;
  max-width: 100%;
  &.is-link{
    .file-name, .file-name__suffix{
      cursor: pointer;
    }
    &:hover{
      .file-name, .file-name__suffix{
        color: #337AF4;
      }
    }
  }
  .file-icon{
    margin-right: 6px;
    font-size: 17px;
    width: 17px!important;
    height: 17px!important;
  }
  .file-name{
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .file-name__suffix{
  }
  .file-name__close-btn{
    cursor: pointer;
    margin-left: 6px;
  }

}
</style>
