import prototype from './prototype'
import filter from './filter'

const registerFilter = Vue => Object.keys(filter).forEach(key => Vue.filter(key, filter[key]))

export default {
  install (Vue) {
    prototype(Vue)
    registerFilter(Vue)
  }
}
