import { render, staticRenderFns } from "./GroupField.vue?vue&type=template&id=15ccb166&scoped=true&"
import script from "./GroupField.vue?vue&type=script&lang=js&"
export * from "./GroupField.vue?vue&type=script&lang=js&"
import style0 from "./GroupField.vue?vue&type=style&index=0&id=15ccb166&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15ccb166",
  null
  
)

export default component.exports