import axios from 'axios'
import { Message } from '@shipout/ui'

const ERROR_STYLE = 'background-color:red;color:#fff;'

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE
})

// 干预每次的请求
instance.interceptors.request.use(
  function (config) {
    const AUTH = window.localStorage.getItem('access_token')
    if (AUTH) {
      config.headers.Authorization = AUTH
    }
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    if (userInfo && userInfo.userId) {
      config.params = config.params || {}
      config.params.userId = config.params.userId || userInfo.userId
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  })

// 干预每次的返回
instance.interceptors.response.use(
  function (response) {
    const SUCCESS_CODE = 'OK'
    // 如果请求头里的 header 有个 needReturnAll 则直接返回 response
    if (response.config.headers.needReturnAll) return response
    if (response.config.headers.isNotAutoError) return response
    // 如果有数据而且状态码为成功，则返回数据
    if (response.data && response.data.result === SUCCESS_CODE) return response.data
    // __isFulFilled 字段用来区分是接口返回的错误（true），还是接口根本就没有通（非 true）
    if (process.env.NODE_ENV !== 'production') {
      console.log('%c【开发日志】%s %s 返回成功，但 data.code !== 200， res=%o', ERROR_STYLE, response.config.method.toUpperCase(), response.config.url, response.data)
    }

    if (response.data && response.data.result === 'ERR') {
      Message({
        message: response.data.message,
        type: 'error'
      })
      return Promise.reject(response.data)
    }
    return Promise.reject(Object.assign({ __isFulFilled: true }, response.data))
  },
  function (error) {
    // 如果请求头里的 header 有个 needReturnAll 则直接返回 response
    if (error.response && error.response.config.headers.needReturnAll) return Promise.reject(error.response)
    if (process.env.NODE_ENV !== 'production') {
      console.log('%c【开发日志】接口失败，err=%o', ERROR_STYLE, JSON.parse(JSON.stringify(error)))
    }
    return Promise.reject(error.response && error.response.data)
  })

export default instance
