<template>
  <span
    class="c--text-copy"
    @click="handleCopy"
  >
    <ez-tooltip :content="$t('common.copy')" placement="top">
      <slot>
        <ez-icon icon="Icon_Copy" class="ez-icon--color-inherit" />
      </slot>
    </ez-tooltip>
  </span>
</template>

<script>
import clipboardCopy from 'clipboard-copy'

export default {
  name: 'TextCopy',
  props: {
    text: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleCopy () {
      clipboardCopy(this.text)
        .then(() => {
          this.$message.success({ message: 'Success' })
        })
        .catch(error => {
          console.error(error)
        })
    }
  }
}
</script>

<style scoped lang="scss">
.c--text-copy{
  color: rgb(198, 209, 222);
  padding-left: 4px;
  cursor: copy;
  svg{
    color: #A7B1BF;
    vertical-align: middle;
  }
}
</style>
