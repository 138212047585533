<template>
  <div class="c--group-field">
    <ez-select
      :value="value"
      class="pre-field"
      @change="handleChange"
    >
      <ez-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      />
    </ez-select>
    <div class="append-field">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'GroupField',
  props: {
    options: {
      type: Array,
      default () {
        return []
      }
    },
    value: {
      type: [String, Number],
      default: null
    }
  },
  methods: {
    handleChange (val) {
      this.$emit('input', val)
    }
  }
}
</script>

<style scoped lang="scss">
.c--group-field{
  display: flex;
  align-items: center;
  /deep/ .ez-date-editor{
    width: 360px;
    .ez-range-input{
      flex: 1;
    }
  }
  .pre-field{
    /deep/ .ez-input{
      width: 150px;
      margin-right: 0!important;
      .ez-input__inner{
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
        border-color: #D3D9EB!important;
      }
    }
  }
  .append-field{
    /deep/ .ez-input__inner{
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

</style>
